import { FC, useState, useEffect } from "react";
import {
    Button,
    Card,
    Col,
    Content, Input, Paragraph,
    Row,
    Space,
    Title,
} from "../layout/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { Modal, Pagination } from "../layout/Defaults";
import GLRenderer from "../interactive/GLRenderer";
import { OakMesh } from "../../model/OakObject";

interface Mesh {
    uuid: string;
    name: string;
}

interface MeshSelectorProps {
    meshUuid: string | null;
    onUpdate: (uuid: string | null) => void;
}

const MeshSelector: FC<MeshSelectorProps> = ({ meshUuid, onUpdate }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [meshes, setMeshes] = useState<Mesh[]>([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedMeshData, setSelectedMeshData] = useState<Mesh | null>(null);

    useEffect(() => {
        if (modalVisible) {
            fetchMeshes(page, limit);
        }
    }, [modalVisible, page, limit]);

    useEffect(() => {
        if (meshUuid) {
            fetchMeshData(meshUuid);
        } else {
            setSelectedMeshData(null);
        }
    }, [meshUuid]);

    const fetchMeshes = async (page: number, limit: number) => {
        setLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_DELEGATOR}/mesh/statistics?page=${page}&limit=${limit}`
            );
            if (!response.ok) throw new Error("Failed to fetch meshes");
            const data = await response.json();
            setMeshes(data.data || []);
            setTotal(data.total || 0);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchMeshData = async (uuid: string) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_DOMAIN_DELEGATOR}/mesh/${uuid}`
            );
            if (!response.ok) throw new Error("Failed to fetch mesh data");
            const data = await response.json();
            setSelectedMeshData(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSelectMesh = (uuid: string) => {
        onUpdate(uuid);
        setModalVisible(false);
    };

    const handleClear = () => {
        onUpdate(null);
    };

    return (
        <div>

            <Row>
                <Col xs={12}>
            <Space direction={"vertical"}>
                Entity Mesh:
                {selectedMeshData && (
                    <div style={{width: "100%"}}>
                        <GLRenderer
                            key={`entity-mesh-${selectedMeshData.uuid}`}
                            options={{
                                autoRotate: true,
                                width: "100%",
                                height: 128,
                                buffersUUID: "any",
                                backgroundColor: [Math.random(), Math.random(), Math.random(), 1],
                                PLYDataGenerator: () => {
                                    return {
                                        ready: false,
                                        static: new OakMesh(undefined, selectedMeshData as OakMesh).getPLYData(),
                                        dynamic: undefined,
                                        shadows: undefined,
                                    };
                                },
                            }}
                        />
                </div>
                )}
<Space GapSm>
                {meshUuid&&<Input
                    Wide
                    size={"small"}
                    type={"text"}
                        value={meshUuid || ""}
                        readOnly
                        style={{ flex: 1 }}
                    />}
                    <Button onClick={() => setModalVisible(true)}>Select Mesh</Button>
                    {meshUuid && (
                        <Button type="danger" onClick={handleClear}>
                            <FontAwesomeIcon icon={faTrashCan} /> Clear
                        </Button>
                    )}
</Space>

            </Space>
                </Col>
            </Row>

            {modalVisible && (
                <Modal
                    visible={modalVisible}
                    title={<Paragraph>Select a Mesh</Paragraph>}
                    content={
                        <Content>
                            <Space direction="vertical" GapSm>
                                <Row GapSm>
                                    {loading && <div>Loading...</div>}
                                    {!loading &&
                                        meshes.map((mesh) => (
                                            <Col xs={12} md={8} lg={6} key={mesh.uuid} >
                                                <Card PadSm Full>
                                                    <Space direction="vertical" GapSm>
                                                        <strong className={"overflow-ellipsis"}>{mesh.name}</strong>
                                                        <GLRenderer
                                                            key={mesh.uuid}
                                                            options={{
                                                                autoRotate: true,
                                                                width: "100%",
                                                                height: 128,
                                                                buffersUUID: "any",
                                                                backgroundColor: [Math.random(), Math.random(), Math.random(), 1],
                                                                PLYDataGenerator: () => {
                                                                    return {
                                                                        ready: false,
                                                                        static: new OakMesh(undefined, mesh as OakMesh).getPLYData(),
                                                                        dynamic: undefined,
                                                                        shadows: undefined,
                                                                    };
                                                                },
                                                            }}
                                                        />
                                                        {/*<small>{mesh.uuid}</small>*/}
                                                        <Button
                                                            type="active"
                                                            onClick={() => handleSelectMesh(mesh.uuid)}
                                                        >
                                                            <FontAwesomeIcon icon={faCheck} /> Select
                                                        </Button>
                                                    </Space>
                                                </Card>
                                            </Col>
                                        ))}
                                </Row>
                                <Pagination
                                    current={page}
                                    total={Math.ceil(total / limit)}
                                    onChange={(p: number) => setPage(p)}
                                />
                            </Space>
                        </Content>
                    }
                    onClose={() => setModalVisible(false)}
                />
            )}
        </div>
    );
};

export default MeshSelector;
